import { ModelAPI } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const LOGIN_REQUEST_ENDPOINT = '/login/request/'
const LOGIN_CLASSIC_ENDPOINT = '/login/classic/'
const LOGIN_WITH_TOKEN_ENDPOINT = '/login/confirm/'
const LOGOUT_ENDPOINT = '/logout/'
const USERS_ENDPOINT = '/users/'
const SELF_ENDPOINT = '/users/self/'
const PASSWORD_RESET_ENDPOINT = '/password/reset/confirm/'

export default class UserAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = USERS_ENDPOINT

  retrieveSelf() {
    return this.client
      .get(SELF_ENDPOINT)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.retrieveSelf',
        }),
      )
  }

  requestLoginLink(email, redirectToRouteName = '') {
    let url = `${LOGIN_REQUEST_ENDPOINT}?email=${email.toLowerCase()}`
    if (redirectToRouteName) url += `&redirect_to_route_name=${redirectToRouteName}`

    return this.client.get(url).catch(
      apiErrorHandler({
        apiName: 'UserAPI.requestLoginLink',
      }),
    )
  }

  loginWithToken({ uid, token }) {
    const data = { uid, token }
    return this.client
      .post(LOGIN_WITH_TOKEN_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'UserAPI.loginWithToken', enable400Alert: false }))
  }

  loginWithEmailAndPassword(email, password) {
    const data = { email, password }
    return this.client
      .post(LOGIN_CLASSIC_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({ apiName: 'UserAPI.loginWithEmailAndPassword', enable400Alert: false }),
      )
  }

  logout() {
    return this.client.post(LOGOUT_ENDPOINT).catch(apiErrorHandler({ apiName: 'UserAPI.logout' }))
  }

  resetPassword({ uid, token, password }) {
    const data = { uid, token, password }
    return this.client
      .post(PASSWORD_RESET_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'UserAPI.resetPassword' }))
  }
}
