import { toast as t } from 'vue3-toastify'

export const toast = (msg, type = 'default', autoClose = 5000) =>
  t(msg, {
    position: t.POSITION.TOP_RIGHT,
    autoClose: autoClose,
    type,
  })

// Keep error toasts around longer so client can read the error / reach out to support email
export const errorToast = (msg) => toast(msg, 'error', 15000)

export const infoToast = (msg) => toast(msg, 'info')

export const successToast = (msg) => toast(msg, 'success')
