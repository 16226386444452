<template>
  <div class="flex flex-col h-full antialiased" data-cy="app">
    <div class="flex items-center justify-between mt-4">
      <img
        :src="require('@/assets/FirstRoundLogoBlack.svg')"
        alt="First Round Capital logo"
        class="w-20 cursor-pointer"
        @click="$router.push({ name: 'Home' })"
      />
      <button @click="toggleMenu" class="md:hidden p-2 z-50">
        <span>
          <img :src="require('@/assets/hamburger.svg')" alt="Menu" class="w-8" />
        </span>
      </button>
      <div
        v-if="isLoggedIn && isMenuOpen"
        class="fixed inset-0 bg-black bg-opacity-50 z-40"
        @click.self="closeMenu"
      ></div>
      <div
        ref="menuRef"
        :class="[
          'fixed md:relative top-16 right-0 md:top-0 w-full md:w-auto',
          'bg-white shadow-lg md:shadow-none',
          'z-50 transition-all ease-in-out duration-300 md:shadow-none',
          isMenuOpen || mdAndUp ? 'flex' : 'hidden',
          'flex-col md:flex-row',
        ]"
        v-show="isLoggedIn"
      >
        <router-link
          :to="{ name: 'Query' }"
          class="font-bold px-3 py-2 border-b md:border-none"
          @click="isMenuOpen === mdAndUp ? true : false"
        >
          Search
        </router-link>
        <router-link
          :to="{ name: 'CompanyOnePager' }"
          class="font-bold px-3 py-2 border-b md:border-none"
          @click="isMenuOpen === mdAndUp ? true : false"
        >
          Company Lookup
        </router-link>
        <router-link
          :to="{ name: 'InvestorsIndex' }"
          class="font-bold px-3 py-2 border-b md:border-none"
        >
          Investors
        </router-link>
        <router-link
          :to="{ name: 'InvestorSearch' }"
          class="font-bold px-3 py-2 border-b md:border-none"
        >
          Investor Search
        </router-link>
        <span id="logout" class="font-bold px-3 py-2" @click="logout"> Logout </span>
      </div>
    </div>
    <main class="flex w-full h-full my-8">
      <router-view />
    </main>
    <GlobalLoading v-if="loading" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import User from '@/services/users/'
import GlobalLoading from '@/components/GlobalLoading'

const ROUTES_WITH_HIDDEN_MOBILE_HEADER = ['Query']

export default {
  name: 'App',
  components: { GlobalLoading },
  setup() {
    const store = useStore()
    const router = useRouter()
    const isMenuOpen = ref(false)
    const menuRef = ref(null)
    const mdAndUp = ref(window.innerWidth >= 768) // breakpoint for "md"

    const updateScreenSize = () => {
      mdAndUp.value = window.innerWidth >= 768
    }

    async function handleLogoutSuccess() {
      await store.dispatch('setUser', null)
      router.push({ name: 'Home' })
    }

    async function logout() {
      User.api.logout().finally(handleLogoutSuccess)
    }

    function toggleMenu() {
      isMenuOpen.value = !isMenuOpen.value
    }

    function closeMenu() {
      isMenuOpen.value = false
    }

    onMounted(() => {
      updateScreenSize()
      window.addEventListener('resize', updateScreenSize)
      store.dispatch('setLoading', false)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenSize)
    })

    return {
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      loading: computed(() => store.state.loading),
      logout,
      toggleMenu,
      closeMenu,
      isMenuOpen,
      mdAndUp,
      menuRef,
    }
  },
  computed: {
    hideMobileHeader() {
      return ROUTES_WITH_HIDDEN_MOBILE_HEADER.includes(this.$route.name)
    },
  },
}
</script>
