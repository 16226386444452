import { createRouter, createWebHistory } from 'vue-router'
import { homeGuard, requireAuth, redirectIfLoggedIn } from './guards'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: [homeGuard],
  },
  {
    path: '/auth/login/:uid/:token',
    name: 'LoginLinkAuth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loginLinkAuth" */ '../views/LoginLinkAuth.vue'),
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: [redirectIfLoggedIn('Home')],
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/login/classic',
    name: 'LoginClassic',
    beforeEnter: [redirectIfLoggedIn('Home')],
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginClassic.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
  },
  {
    path: '/search',
    name: 'Query',
    beforeEnter: [requireAuth],
    component: () => import(/* webpackChunkName: "query" */ '../views/Query.vue'),
  },
  {
    path: '/waii/search',
    name: 'WaiiSearch',
    beforeEnter: [requireAuth],
    component: () => import(/* webpackChunkName: "query" */ '../views/WaiiQuery.vue'),
  },
  {
    path: '/investors',
    name: 'InvestorsIndex',
    beforeEnter: [requireAuth],
    component: () =>
      import(/* webpackChunkName: "investors" */ '../views/Investors/InvestorsIndex.vue'),
  },
  {
    path: '/investors/search',
    name: 'InvestorSearch',
    beforeEnter: [requireAuth],
    component: () =>
      import(/* webpackChunkName: "investorSearch" */ '../views/Investors/InvestorSearch.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
  {
    path: '/companies/lookup',
    name: 'CompanyOnePager',
    beforeEnter: [requireAuth],
    component: () =>
      import(/* webpackChunkName: "companyOnePager" */ '../views/Companies/CompanyOnePager.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior: () => ({
    el: '#app',
    top: 2,
  }),
})

export default router
