import store from '@/store'
import User from '@/services/users'

/**
 * Route Guard.
 * All logged in users can access the route.
 * If not logged in, a user will be redirected to the login page.
 */
export function requireAuth(to, from, next) {
  if (!store.getters.isLoggedIn) {
    next({ name: 'Login', query: { redirect: to.name } })
  } else {
    next()
  }
}

/**
 * RETURNS Route Guard.
 * If logged in, a user will be redirected to the route by name of args.redirectTo.
 */
export const redirectIfLoggedIn = (redirectTo) => (to, from, next) => {
  const { redirect } = to.query
  if (redirect && store.getters.isLoggedIn) next({ name: redirect })
  if (store.getters.isLoggedIn) next({ name: redirectTo })
  else next()
}

/**
 * Route Guard for root endpoint.
 * Each user type is redirected to an appropriate first page.
 */
export function homeGuard(to, from, next) {
  if (store.getters.isLoggedIn) return next({ name: 'Query' })
  next({ name: 'Login' })
}
