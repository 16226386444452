import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SET_USER, SET_LOADING } from './mutation-types'

const STORAGE_HASH = '4XDbAIDExq'
export const STORAGE_KEY = `frc-gpt-${STORAGE_HASH}`

const state = {
  user: null,
  loading: false,
}

const mutations = {
  [SET_USER]: (state, payload) => {
    state.user = payload
  },
  [SET_LOADING]: (state, value) => {
    state.loading = value
  },
}

const actions = {
  setUser({ commit }, user) {
    commit(SET_USER, user)
  },
  setLoading({ commit }, value) {
    commit(SET_LOADING, value)
  },
  logout({ commit }) {
    commit(SET_USER, null)
  },
}

const getters = {
  isLoggedIn: (state) => {
    return !!state.user
  },
  user: (state) => {
    return state.user
  },
  token: (state) => {
    return state.user ? state.user.token : null
  },
}

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({
      key: STORAGE_KEY,
    }),
  ],
})

export default store
